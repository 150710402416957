@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nexa';
  src:  local(''),
  url('./assets/fonts/Nexa-Regular.woff2') format('woff2'),
  url('./assets/fonts/Nexa-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src:  local(''),
  url('./assets/fonts/Nexa-ExtraBold.woff') format('woff2'),
  url('./assets/fonts/Nexa-ExtraBold.woff') format('woff');

  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src:  local(''),
  url('./assets/fonts/Nexa-Heavy.woff2') format('woff2'),
  url('./assets/fonts/Nexa-Heavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src:  local(''),
  url('./assets/fonts/Nexa-Black.woff') format('woff2'),
  url('./assets/fonts/Nexa-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Nexa', sans-serif; /* Fallback to a generic sans-serif font if Nexa is not available */
}


.customCursorStyle {
  position: fixed;
  width: 20px;
  height: 20px;
  background-size: cover;
  z-index: 9999;
};

/* Add this to your CSS file or style section */
.blur-background {
  position: relative;
  overflow: hidden;
}

.blur-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  filter: blur(10px); /* Adjust the blur intensity as needed */
  z-index: -1;
}
