.plantas {
  background: #1faae1 0% 0% no-repeat padding-box;
  opacity: 1;
}

.plant-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.plant-info {
  flex-grow: 1;
}

.plant-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.plant-componente,
.plant-area {
  font-size: 14px;
  margin-bottom: 5px;
}

.plant-image {
  width: 100px;
  height: auto;
  border-radius: 4px;
}

.plant-buttons {
  display: flex;
  align-items: center;
}

.plant-button-edit,
.plant-button-remove {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.plant-button-edit {
  color: #007bff;
}

.plant-button-remove {
  color: #ff0000;
}

.modal-back {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  max-width: 400px;
  margin: 0 auto;
}

.input-back {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.quilcomponent {
  width: 100%;
  padding: 4px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-back {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.button-primary-back {
  background-color: #3182ce;
  color: #ffffff;
}

.button-secondary-back {
  background-color: #e53e3e;
  color: #ffffff;
}

.teste {
  height: 100% !important;
  width: auto;
}
/* CSS */
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

              
/* Container do chatbot */

/* .chat-bot-container {
  position: fixed;
  bottom: 65px;
  right: 0px; 
  background-color: transparent;
  width: 30vw;
  height: 60vh;
  display: block;
  z-index: 999;
}

.chat-bot-iframe{
  width: 100%;
  height: 100%;
  border: none;
} */