/* Estilos específicos para o componente HeaderMobile */

.mobile-header-container {
    background-color: #ffffff;
    padding: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  
  /* Estilos para o menu responsivo */
  .mobile-header-menu {
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .mobile-header-menu a {
    display: block;
    color: #333333;
    font-size: 14px;
    margin-bottom: 8px;
    text-decoration: none;
  }
  
  .mobile-header-menu a:hover {
    color: #007bff;
  }
  