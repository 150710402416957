.navigation-container {
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Cor de fundo com opacidade 50% */
  padding: 10px;
  border-radius: 8px;
}

#website-content {
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
}

.image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* gallery.css (or your preferred CSS file) */

